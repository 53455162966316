<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <header-image :title="headerTitle"></header-image>
    <!-- /头部 -->
    <!-- 体部 -->
    <div>
      <div class="ban-about">
        <ul class="subnav">
          <li class="active">
            <h1>公司简介</h1>
          </li>
        </ul>
      </div>
    </div>
    <div class="text-p">
      <p style="font-size: 18px">北京新元互动科技有限公司是一家创新型互联网推广高新企业，接入华为、OPPO、哔哩哔哩、快看漫画、VIVO等众多媒体信息流资源；与头条、开心消消乐、小米等各类资源合作。</P>
      <p style="font-size: 18px">合作模式：</p>
      <p style="font-size: 15px">信息流、厂商网络消息、效果投放、快应用等方式方法。另有覆盖社区业主群体的微信群、电梯、小区广告媒体或设备覆盖。</p>
      <P style="font-size: 18px">团队规模：</P>
      <p style="font-size: 15px">拥有20人的80~90后优化团队，曾投放优化过有钱花，七猫，腾讯微保，众安保险等产品，月消耗800W+，经验丰富。</p>
      <p style="font-size: 15px">拥有成熟稳重的15人商务团队，致力于为每位客户提供更优质服务体验，满足客户的合理需求。</p>
      <p style="font-size: 15px">拥有年轻活力的12人媒体团队，负责对接厂商，社区，中小市场，地推等各类所需资源。经验丰富，投放精准，为客户提供高净值客户。</P>
      <p style="font-size: 18px">荣誉认证：</P>
      <p style="font-size: 15px">2018年：BILIBILI等产品网盟投放核心代理商，360手机助手、QQ浏览器等媒体核心代理商。</p>
      <p style="font-size: 15px">2019年：七猫免费小说、360借条等产品网盟投放核心代理商，硬核联盟、QQ浏览器等媒体核心代理商。</p>
      <p style="font-size: 15px">2020年：微保、水滴保、七猫免费小说等产品投放核心代理商，拼多多、东方头条、 oppo信息流、硬核联盟等媒体核心代理商。</p>
      <p style="font-size: 15px">2021年：百度有钱花、运满满等产品核代理商，OPPO、VIVO、华为、小米等厂商关联合作方。</p>
    </div>

    <!-- /体部 -->

    <!-- 尾部 -->
    <Footer></Footer>
    <!-- /尾部 -->

  </div>
</template>
 
<script>
import { get, post } from "@/utils/request";
import Carousel from "../components/Carousel";
  export default {
    name: "index",
    components: {},
    data() {
      return {
        headerTitle:"关于我们",
    };
  },
  created() {},
  methods: {},

};
</script>

<style lang='scss'>

  .ban-about{
    /*position:absolute;*/
    .subnav {
      .active {
        margin-left: 4px;
        color: #63a35c;
        text-align: center;
        line-height: 50px;
      }
    }
  }
  .text-p{
    text-align: center;
  }
</style>